







































































































































































import BalanceOverview from "@/components/customer/BalanceOverview.vue";
import { defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { getStudentID } from "@/helpers/projectUtils";
import { saveStudentName } from "@/api/projectService";
import { useNotifier } from "@/providers/notifier";
import CompleteProject from "@/components/seller/my-students/CompleteProject.vue";
import ProjectCardAboutProject from "@/components/seller/my-students/ProjectCardAboutProject.vue";
import ProjectCardContactInformation from "@/components/seller/my-students/ProjectCardContactInformation.vue";
import ReadableCategories from "@/components/shared/ReadableCategories.vue";
import StartOnlineSessionButton from "@/components/shared/StartOnlineSessionButton.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ProjectCardSeller",
  components: {
    BalanceOverview,
    CompleteProject,
    ProjectCardAboutProject,
    ProjectCardContactInformation,
    ReadableCategories,
    StartOnlineSessionButton,
  },
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    },
  },
  setup(props) {
    const notify = useNotifier();
    const getProjects = inject("getProjects") as () => Promise<void>;

    const vm = reactive({
      nameChangeLoading: false,
      studentID: getStudentID(props.project.customerUID),
      studentName: "",
    });

    async function saveName() {
      vm.nameChangeLoading = true;
      try {
        await saveStudentName(props.project.ID, vm.studentName);
        await getProjects();
      }
      catch (e) {
        notify({
          title: "Klarte ikke oppdatere navn 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.nameChangeLoading = false;
    }

    return {
      saveName,
      vm,
    };
  },
});
