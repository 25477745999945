
















import BankAccountNumberDialog from "@/components/seller/BankAccountNumberDialog.vue";
import { uiModule } from "@/store/modules/ui";
import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import LearnlinkRequirements from "@/components/profile/LearnlinkRequirements.vue";
import Moment from "@/services/moment";
import MyStudents from "@/components/seller/MyStudentsSeller.vue";
import MyTeamsSeller from "@/components/seller/MyTeamsSeller.vue";

export default defineComponent({
  name: "SellerDashboard",
  components: {
    BankAccountNumberDialog,
    LearnlinkRequirements,
    MyStudents,
    MyTeamsSeller,
  },
  setup() {
    const newUser = computed(() => {
      const twoDaysAgo = Moment().subtract(2, "days").unix();
      return userModule.state.user.registerDate > twoDaysAgo;
    });

    const vm = reactive({
      bankAccountNumberDialogVisible: false,
    });

    onMounted(() => {
      vm.bankAccountNumberDialogVisible = !userModule.state.account.bankAccountNumber;
    });

    return {
      isPro: computed(() => userModule.state.user.pro),
      newUser,
      projectCount: computed(() => uiModule.state.projectCount),
      vm,
    };
  },
});
