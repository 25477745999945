













































import { defineComponent, provide } from "@vue/composition-api";
import { getActiveProjects } from "@/api/projectService";
import { RoutesConfig } from "@/router/Routes";
import { userModule } from "@/store/modules/user";
import { useRouter } from "@/router";
import ProjectCardSeller from "@/components/seller/my-students/ProjectCardSeller.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "MyStudentsSeller",
  components: { ProjectCardSeller },
  setup() {
    const { navigate } = useRouter();

    const { isLoading, data: projects, refresh } = useAsyncData(() =>
      getActiveProjects("seller", userModule.state.userId)
    );

    provide("getProjects", refresh);

    return {
      isLoading,
      navigate,
      projects,
      RoutesConfig,
    };
  },
});
