















































import { getMyActiveTeams } from "@/api/teamService";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { userModule } from "@/store/modules/user";
import { defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import TeamCardSeller from "@/components/seller/my-teams/TeamCardSeller.vue";
import Learnlink from "@learnlink/interfaces";
import SupportPhoneAlert from "../SupportPhoneAlert.vue";

export default defineComponent({
  name: "MyTeamsSeller",
  components: {
    LoadingSpinner,
    TeamCardSeller,
    SupportPhoneAlert,
  },
  setup() {
    const vm = reactive({
      dialogVisible: true,
      loading: false,
      teams: [] as Learnlink.Team.View.FullMetadata[],
      teamsMissingPerformance: [] as Learnlink.Team.View.FullMetadata[],
    });

    onMounted(async () => {
      vm.loading = true;
      await getTeams();
      vm.loading = false;
    });

    async function getTeams() {
      vm.teams = (await getMyActiveTeams(userModule.state.userId)).filter((team) => team.course.location !== "calendar");
    }

    provide("getMyTeams", getTeams);

    return {
      vm,
    };
  },
});
