




































import rules from "@/helpers/formRules";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "BankAccountNumberDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const notify = useNotifier();

    const vm = reactive({
      bankAccountNumber: "",
      loading: false,
    });

    async function save() {
      vm.loading = true;
      try {
        await userModule.actions.updateUserAccount({
          uid: userModule.state.userId,
          bankAccountNumber: vm.bankAccountNumber,
        });
        notify({
          title: "Lagret 😄",
          message: "",
          type: "success",
        });
        _value.value = false;
      }
      catch (e) {
        notify({
          title: "Oops",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      _value,
      rules,
      save,
      vm,
    };
  },
});
