

















import { defineComponent } from "@vue/composition-api";
import { userModule } from "../../store/modules/user";

export default defineComponent({
  name: "LearnlinkRequirements",
  setup() {
    return {
      userModule,
    };
  },
});
