import Learnlink from "@learnlink/interfaces";

export const allCategories: Learnlink.Category.Multiple = {
  63: {
    ID: "63",
    category: 0,
    active: 1,
    level: 0,
    parent: "0",
    title: "Realfag",
    type: 0,
  },
  67: {
    ID: "67",
    category: 1,
    active: 0,
    level: 1,
    parent: "63",
    title: "Matte 1.-10. trinn",
    type: 0,
  },
  68: {
    ID: "68",
    category: 1,
    active: 0,
    level: 3,
    parent: "63",
    title: "P-matte",
    type: 0,
  },
  69: {
    ID: "69",
    category: 1,
    active: 0,
    level: 3,
    parent: "63",
    title: "T-matte",
    type: 0,
  },
  70: {
    ID: "70",
    category: 1,
    active: 0,
    level: 3,
    parent: "63",
    title: "S-matte",
    type: 0,
  },
  71: {
    ID: "71",
    category: 1,
    active: 0,
    level: 3,
    parent: "63",
    title: "R-matte",
    type: 0,
  },
  72: {
    ID: "72",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Naturfag 1. - 7. trinn",
    type: 0,
  },
  73: {
    ID: "73",
    category: 1,
    active: 1,
    level: 1,
    parent: "79",
    title: "Norsk 1. - 7. trinn",
    type: 0,
  },
  74: {
    ID: "74",
    category: 1,
    active: 1,
    level: 1,
    parent: "75",
    title: "Engelsk 1. - 7. trinn",
    type: 0,
  },
  75: {
    ID: "75",
    category: 0,
    active: 1,
    level: 0,
    parent: "0",
    title: "Språk",
    type: 0,
  },
  76: {
    ID: "76",
    category: 0,
    active: 1,
    level: 2,
    parent: "75",
    title: "Tysk 8. - 10. trinn",
    type: 0,
  },
  77: {
    ID: "77",
    category: 0,
    active: 1,
    level: 2,
    parent: "75",
    title: "Spansk 8. - 10. trinn",
    type: 0,
  },
  78: {
    ID: "78",
    category: 0,
    active: 1,
    level: 2,
    parent: "75",
    title: "Fransk 8. - 10. trinn",
    type: 0,
  },
  79: {
    ID: "79",
    category: 0,
    active: 1,
    level: 0,
    parent: "0",
    title: "Allmenfag",
    type: 0,
  },
  80: {
    ID: "80",
    category: 0,
    active: 0,
    level: 3,
    parent: "63",
    title: "Fysikk VGS",
    type: 0,
  },
  81: {
    ID: "81",
    category: 0,
    active: 0,
    level: 0,
    parent: "63",
    title: "Kjemi",
    type: 0,
  },
  82: {
    ID: "82",
    category: 0,
    active: 0,
    level: 0,
    parent: "79",
    title: "Historie",
    type: 0,
  },
  83: {
    ID: "83",
    category: 0,
    active: 0,
    level: 0,
    parent: "79",
    title: "Filosofi",
    type: 0,
  },
  84: {
    ID: "84",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Religion 8. - 10. trinn",
    type: 0,
  },
  85: {
    ID: "85",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Samfunnsfag 8. - 10. trinn",
    type: 0,
  },
  86: {
    ID: "86",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Psykologi VGS",
    type: 0,
  },
  87: {
    ID: "87",
    category: 0,
    active: 1,
    level: 0,
    parent: "0",
    title: "Valgfag",
    type: 0,
  },
  88: {
    ID: "88",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Rettslære VGS",
    type: 0,
  },
  89: {
    ID: "89",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Japansk",
    type: 0,
  },
  90: {
    ID: "90",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Koreansk",
    type: 0,
  },
  91: {
    ID: "91",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Italiensk",
    type: 0,
  },
  92: {
    ID: "92",
    category: 0,
    active: 0,
    level: 0,
    parent: "0",
    title: "IT",
    type: 0,
  },
  93: {
    ID: "93",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Programmering",
    type: 0,
  },
  94: {
    ID: "94",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Databaser",
    type: 0,
  },
  95: {
    ID: "95",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Kinesisk",
    type: 0,
  },
  96: {
    ID: "96",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Geografi 8. - 10. trinn",
    type: 0,
  },
  97: {
    ID: "97",
    category: 0,
    active: 0,
    level: 0,
    parent: "79",
    title: "Medier og Kommunikasjon",
    type: 0,
  },
  98: {
    ID: "98",
    category: 0,
    active: 0,
    level: 0,
    parent: "0",
    title: "Diverse",
    type: 0,
  },
  99: {
    ID: "99",
    category: 0,
    active: 0,
    level: 0,
    parent: "98",
    title: "Kjøretimer",
    type: 0,
  },
  100: {
    ID: "100",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Punjabi",
    type: 0,
  },
  101: {
    ID: "101",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Java",
    type: 0,
  },
  102: {
    ID: "102",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "PHP",
    type: 0,
  },
  103: {
    ID: "103",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "C",
    type: 0,
  },
  104: {
    ID: "104",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "C++",
    type: 0,
  },
  105: {
    ID: "105",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "C#",
    type: 0,
  },
  106: {
    ID: "106",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Ruby",
    type: 0,
  },
  107: {
    ID: "107",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Python",
    type: 0,
  },
  108: {
    ID: "108",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "JavaScript",
    type: 0,
  },
  109: {
    ID: "109",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "HTML / CSS",
    type: 0,
  },
  110: {
    ID: "110",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Nettverk",
    type: 0,
  },
  111: {
    ID: "111",
    category: 2,
    active: 0,
    level: 0,
    parent: "92",
    title: "Informasjonssikkerhet",
    type: 0,
  },
  112: {
    ID: "112",
    category: 0,
    active: 0,
    level: 0,
    parent: "0",
    title: "NTNU",
    type: 0,
  },
  113: {
    ID: "113",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TDT4120 - Algoritmer og datastrukturer",
    type: 0,
  },
  114: {
    ID: "114",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TMA4100 - Matematikk 1",
    type: 0,
  },
  115: {
    ID: "115",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TMA4110 - Matematikk 3",
    type: 0,
  },
  116: {
    ID: "116",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TFY4104 - Fysikk",
    type: 0,
  },
  117: {
    ID: "117",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TMM4112 - Maskindeler",
    type: 0,
  },
  118: {
    ID: "118",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TDT4105 - ITGK",
    type: 0,
  },
  119: {
    ID: "119",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Biologi VGS",
    type: 0,
  },
  120: {
    ID: "120",
    category: 0,
    active: 0,
    level: 0,
    parent: "112",
    title: "TMT4110 - Kjemi",
    type: 0,
  },
  121: {
    ID: "121",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Samfunnsøkonomi VGS",
    type: 0,
  },
  122: {
    ID: "122",
    category: 0,
    active: 0,
    level: 0,
    parent: "98",
    title: "Yoga",
    type: 0,
  },
  123: {
    ID: "123",
    category: 0,
    active: 1,
    level: 3,
    parent: "87",
    title: "Økonomi og ledelse VGS",
    type: 0,
  },
  124: {
    ID: "124",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Matte 1. - 7. trinn",
    type: 0,
  },
  125: {
    ID: "125",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Matte 8. - 10. trinn",
    type: 0,
  },
  126: {
    ID: "126",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Russisk",
    type: 0,
  },
  127: {
    ID: "127",
    category: 0,
    active: 0,
    level: 0,
    parent: "98",
    title: "Piano",
    type: 0,
  },
  128: {
    ID: "128",
    category: 0,
    active: 0,
    level: 0,
    parent: "98",
    title: "Medikamentregning",
    type: 0,
  },
  129: {
    ID: "129",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Kjemi 1 VGS",
    type: 0,
  },
  130: {
    ID: "130",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Kjemi 2",
    type: 0,
  },
  131: {
    ID: "131",
    category: 0,
    active: 0,
    level: 0,
    parent: "75",
    title: "Polsk",
    type: 0,
  },
  132: {
    ID: "132",
    category: 1,
    active: 0,
    level: 1,
    parent: "79",
    title: "Generell leksehjelp",
    type: 0,
  },
  133: {
    ID: "133",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Naturfag 8. - 10. trinn",
    type: 0,
  },
  134: {
    ID: "134",
    category: 1,
    active: 1,
    level: 2,
    parent: "75",
    title: "Engelsk 8. - 10. trinn",
    type: 0,
  },
  135: {
    ID: "135",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Norsk 8. - 10. trinn",
    type: 0,
  },
  136: {
    ID: "136",
    category: 1,
    active: 0,
    level: 2,
    parent: "79",
    title: "Studieteknikk 8. - 10. trinn",
    type: 0,
  },
  137: {
    ID: "137",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 1P",
    type: 0,
  },
  138: {
    ID: "138",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 2P",
    type: 0,
  },
  139: {
    ID: "139",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R1",
    type: 0,
  },
  140: {
    ID: "140",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R2",
    type: 0,
  },
  141: {
    ID: "141",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S1",
    type: 0,
  },
  142: {
    ID: "142",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S2",
    type: 0,
  },
  143: {
    ID: "143",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 1T",
    type: 0,
  },
  144: {
    ID: "144",
    category: 1,
    active: 0,
    level: 3,
    parent: "63",
    title: "Matte T2",
    type: 0,
  },
  145: {
    ID: "145",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte (Vet ikke hvilket fag) VGS",
    type: 0,
  },
  146: {
    ID: "146",
    category: 0,
    active: 1,
    level: 3,
    parent: "63",
    title: "Fysikk 1 VGS",
    type: 0,
  },
  147: {
    ID: "147",
    category: 0,
    active: 1,
    level: 3,
    parent: "63",
    title: "Fysikk 2 VGS",
    type: 0,
  },
  148: {
    ID: "148",
    category: 0,
    active: 1,
    level: 3,
    parent: "75",
    title: "Tysk VGS",
    type: 0,
  },
  149: {
    ID: "149",
    category: 0,
    active: 1,
    level: 3,
    parent: "75",
    title: "Spansk VGS",
    type: 0,
  },
  150: {
    ID: "150",
    category: 0,
    active: 1,
    level: 3,
    parent: "75",
    title: "Fransk VGS",
    type: 0,
  },
  151: {
    ID: "151",
    category: 1,
    active: 1,
    level: 3,
    parent: "79",
    title: "Norsk VGS",
    type: 0,
  },
  152: {
    ID: "152",
    category: 1,
    active: 1,
    level: 3,
    parent: "75",
    title: "Engelsk VGS",
    type: 0,
  },
  153: {
    ID: "153",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Naturfag VGS",
    type: 0,
  },
  154: {
    ID: "154",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Politikk og menneskerettigheter VGS",
    type: 0,
  },
  155: {
    ID: "155",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Samfunnsgeografi VGS",
    type: 0,
  },
  156: {
    ID: "156",
    category: 1,
    active: 1,
    level: 3,
    parent: "79",
    title: "Religion VGS",
    type: 0,
  },
  157: {
    ID: "157",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Biologi 1",
    type: 0,
  },
  158: {
    ID: "158",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Biologi 2",
    type: 0,
  },
  159: {
    ID: "159",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Scratch-kurs 1–1",
    type: 0,
  },
  160: {
    ID: "160",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Kodekurs",
    type: 0,
  },
  161: {
    ID: "161",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Koding 1-1",
    type: 0,
  },
};

export const activeCategories: Learnlink.Category.Multiple = {
  72: {
    ID: "72",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Naturfag 1. - 7. trinn",
    type: 0,
  },
  73: {
    ID: "73",
    category: 1,
    active: 1,
    level: 1,
    parent: "79",
    title: "Norsk 1. - 7. trinn",
    type: 0,
  },
  74: {
    ID: "74",
    category: 1,
    active: 1,
    level: 1,
    parent: "75",
    title: "Engelsk 1. - 7. trinn",
    type: 0,
  },
  124: {
    ID: "124",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Matte 1. - 7. trinn",
    type: 0,
  },
  125: {
    ID: "125",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Matte 8. - 10. trinn",
    type: 0,
  },
  132: {
    ID: "132",
    category: 1,
    active: 0,
    level: 1,
    parent: "79",
    title: "Generell leksehjelp",
    type: 0,
  },
  133: {
    ID: "133",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Naturfag 8. - 10. trinn",
    type: 0,
  },
  134: {
    ID: "134",
    category: 1,
    active: 1,
    level: 2,
    parent: "75",
    title: "Engelsk 8. - 10. trinn",
    type: 0,
  },
  135: {
    ID: "135",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Norsk 8. - 10. trinn",
    type: 0,
  },
  137: {
    ID: "137",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 1P",
    type: 0,
  },
  138: {
    ID: "138",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 2P",
    type: 0,
  },
  139: {
    ID: "139",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R1",
    type: 0,
  },
  140: {
    ID: "140",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R2",
    type: 0,
  },
  141: {
    ID: "141",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S1",
    type: 0,
  },
  142: {
    ID: "142",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S2",
    type: 0,
  },
  143: {
    ID: "143",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte T1",
    type: 0,
  },
  145: {
    ID: "145",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte (Vet ikke hvilket fag) VGS",
    type: 0,
  },
  151: {
    ID: "151",
    category: 1,
    active: 1,
    level: 3,
    parent: "79",
    title: "Norsk VGS",
    type: 0,
  },
  152: {
    ID: "152",
    category: 1,
    active: 1,
    level: 3,
    parent: "75",
    title: "Engelsk VGS",
    type: 0,
  },
  153: {
    ID: "153",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Naturfag VGS",
    type: 0,
  },
  159: {
    ID: "159",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Scratch-kurs 1–1",
    type: 0,
  },
  160: {
    ID: "160",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Kodekurs",
    type: 0,
  },
  161: {
    ID: "161",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Koding 1-1",
    type: 0,
  },
};

export const categoriesWithoutLevelName: Learnlink.Category.Multiple = {
  72: {
    ID: "72",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Naturfag",
    type: 0,
  },
  73: {
    ID: "73",
    category: 1,
    active: 1,
    level: 1,
    parent: "79",
    title: "Norsk",
    type: 0,
  },
  74: {
    ID: "74",
    category: 1,
    active: 1,
    level: 1,
    parent: "75",
    title: "Engelsk",
    type: 0,
  },
  76: {
    ID: "76",
    category: 0,
    active: 1,
    level: 2,
    parent: "75",
    title: "Tysk",
    type: 0,
  },
  77: {
    ID: "77",
    category: 0,
    active: 1,
    level: 2,
    parent: "75",
    title: "Spansk",
    type: 0,
  },
  78: {
    ID: "78",
    category: 0,
    active: 1,
    level: 2,
    parent: "75",
    title: "Fransk",
    type: 0,
  },
  84: {
    ID: "84",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Religion",
    type: 0,
  },
  85: {
    ID: "85",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Samfunnsfag",
    type: 0,
  },
  86: {
    ID: "86",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Psykologi",
    type: 0,
  },
  88: {
    ID: "88",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Rettslære",
    type: 0,
  },
  96: {
    ID: "96",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Geografi",
    type: 0,
  },
  119: {
    ID: "119",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Biologi",
    type: 0,
  },
  121: {
    ID: "121",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Samfunnsøkonomi",
    type: 0,
  },
  123: {
    ID: "123",
    category: 0,
    active: 1,
    level: 3,
    parent: "87",
    title: "Økonomi og ledelse",
    type: 0,
  },
  124: {
    ID: "124",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Matte",
    type: 0,
  },
  125: {
    ID: "125",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Matte",
    type: 0,
  },
  129: {
    ID: "129",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Kjemi 1",
    type: 0,
  },
  130: {
    ID: "130",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Kjemi 2",
    type: 0,
  },
  132: {
    ID: "132",
    category: 1,
    active: 0,
    level: 1,
    parent: "79",
    title: "Generell leksehjelp",
    type: 0,
  },
  133: {
    ID: "133",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Naturfag",
    type: 0,
  },
  134: {
    ID: "134",
    category: 1,
    active: 1,
    level: 2,
    parent: "75",
    title: "Engelsk",
    type: 0,
  },
  135: {
    ID: "135",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Norsk",
    type: 0,
  },
  136: {
    ID: "136",
    category: 1,
    active: 0,
    level: 2,
    parent: "79",
    title: "Studieteknikk",
    type: 0,
  },
  137: {
    ID: "137",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 1P",
    type: 0,
  },
  138: {
    ID: "138",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 2P",
    type: 0,
  },
  139: {
    ID: "139",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R1",
    type: 0,
  },
  140: {
    ID: "140",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R2",
    type: 0,
  },
  141: {
    ID: "141",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S1",
    type: 0,
  },
  142: {
    ID: "142",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S2",
    type: 0,
  },
  143: {
    ID: "143",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte T1",
    type: 0,
  },
  145: {
    ID: "145",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte (Vet ikke hvilket fag)",
    type: 0,
  },
  146: {
    ID: "146",
    category: 0,
    active: 1,
    level: 3,
    parent: "63",
    title: "Fysikk 1",
    type: 0,
  },
  147: {
    ID: "147",
    category: 0,
    active: 1,
    level: 3,
    parent: "63",
    title: "Fysikk 2",
    type: 0,
  },
  148: {
    ID: "148",
    category: 0,
    active: 1,
    level: 3,
    parent: "75",
    title: "Tysk",
    type: 0,
  },
  149: {
    ID: "149",
    category: 0,
    active: 1,
    level: 3,
    parent: "75",
    title: "Spansk",
    type: 0,
  },
  150: {
    ID: "150",
    category: 0,
    active: 1,
    level: 3,
    parent: "75",
    title: "Fransk",
    type: 0,
  },
  151: {
    ID: "151",
    category: 1,
    active: 1,
    level: 3,
    parent: "79",
    title: "Norsk",
    type: 0,
  },
  152: {
    ID: "152",
    category: 1,
    active: 1,
    level: 3,
    parent: "75",
    title: "Engelsk",
    type: 0,
  },
  153: {
    ID: "133",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Naturfag",
    type: 0,
  },
  154: {
    ID: "154",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Politikk og menneskerettigheter",
    type: 0,
  },
  155: {
    ID: "155",
    category: 1,
    active: 1,
    level: 3,
    parent: "87",
    title: "Samfunnsgeografi",
    type: 0,
  },
  156: {
    ID: "156",
    category: 1,
    active: 1,
    level: 3,
    parent: "79",
    title: "Religion",
    type: 0,
  },
  157: {
    ID: "157",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Biologi 1",
    type: 0,
  },
  158: {
    ID: "158",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Biologi 2",
    type: 0,
  },
  159: {
    ID: "159",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Scratch-kurs 1–1",
    type: 0,
  },
  160: {
    ID: "160",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Kodekurs",
    type: 0,
  },
  161: {
    ID: "161",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Koding 1-1",
    type: 0,
  },
};
