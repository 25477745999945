












import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import { weekdays } from "@/helpers/timeUtils";

export default defineComponent({
  name: "ProjectWeekdayPicker",
  props: {
    daysAndTimes: {
      type: Array as PropType<string[]>,
      default: () => weekdays,
    },
    label: {
      type: String as PropType<string>,
      default: ""
    },
    value: {
      type: Array as PropType<string[]>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      weekdays,
      _value
    };
  },
});
