















































import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ProjectCardContactInformation",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    },
  },
});
