















































import TeamInformationSeller from "@/components/seller/my-teams/TeamInformationSeller.vue";
import { computed, defineComponent, PropType, reactive } from "@vue/composition-api";
import { createLinks } from "@/helpers/stringUtils";
import { getTeamSessionsForTeam } from "@/api/teamSessionService";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import Moment from "moment";
import TeamCardHeader from "@/components/shared/TeamCardHeader.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "TeamCardSeller",
  components: {
    TeamInformationSeller,
    TeamCardHeader,
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      showContractDialog: false,
    });

    function openCourseProgram() {
      window.open("https://undervisningilearnlink.notion.site/28b66de8efb44f5e8a6e86c68a5882fb?v=c0fbdf76ca2d4655a867e6a3f2846ec7", "_blank");
    }

    const { data: dates } = useAsyncData(() => getTeamSessionsForTeam(props.team.ID));
    const sessionsLeft = computed(() => dates.value?.filter((session) => session.startTime > Moment().unix()).length || 12);

    const signedContract = computed(() => {
      return props.team.contractsSigned.length > 0;
    });

    return {
      openCourseProgram,
      createLinks,
      sessionsLeft,
      signedContract,
      userModule,
      vm,
    };
  },
});
