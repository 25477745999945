




















































import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "AboutStudent",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.default>,
      required: true,
    },
  },
});
